"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _redux = require("redux");
var _registrationDataReducer = _interopRequireDefault(require("./registrationDataReducer"));
var _errorMessageReducer = _interopRequireDefault(require("./errorMessageReducer"));
var _modalDialogReducer = _interopRequireDefault(require("./modalDialogReducer"));
var _otherRegReducer = _interopRequireDefault(require("./otherRegReducer"));
var _bannerAlertReducer = _interopRequireDefault(require("./bannerAlertReducer"));
var _inviteReducer = _interopRequireDefault(require("./inviteReducer"));
var _primaryContactReducer = _interopRequireDefault(require("./primaryContactReducer"));
var _eligibleAppsReducer = _interopRequireDefault(require("./eligibleAppsReducer"));
var _primaryCredentialsReducer = _interopRequireDefault(require("./primaryCredentialsReducer"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var appReducer = (0, _redux.combineReducers)({
  registrationDataReducer: _registrationDataReducer["default"],
  errorMessageReducer: _errorMessageReducer["default"],
  modalDialogReducer: _modalDialogReducer["default"],
  otherRegReducer: _otherRegReducer["default"],
  bannerAlertReducer: _bannerAlertReducer["default"],
  inviteReducer: _inviteReducer["default"],
  primaryContactReducer: _primaryContactReducer["default"],
  eligibleAppsReducer: _eligibleAppsReducer["default"],
  primaryCredentialsReducer: _primaryCredentialsReducer["default"]
});
var rootReducer = function rootReducer(state, action) {
  if (action.type === 'RESET_STATE') {
    state = undefined;
  }
  return appReducer(state, action);
};
var _default = exports["default"] = rootReducer;