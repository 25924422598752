"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setAdditionalPCAttribute = exports.setAdditionalBidScoutAttribute = exports.setAdditionalAttribute = exports.getSplitedNumber = exports.getPolicyEligibility = exports.getPcEligibility = exports.getParameterByName = exports.getObject = exports.GetDropDownData = exports.GetAdditionalAttributes = void 0;
var GetDropDownData = exports.GetDropDownData = function GetDropDownData(defaultText, dataArr, key, value) {
  var list = [];
  if (dataArr && dataArr.length) {
    list = dataArr.map(function (item) {
      return {
        code: item[key],
        name: item[value]
      };
    });
    if (defaultText) list.splice(0, 0, {
      code: '-1',
      name: defaultText
    });
    return list;
  }
};

// ToDo changes to be done in Coremetrics story.
var setAdditionalBidScoutAttribute = exports.setAdditionalBidScoutAttribute = function setAdditionalBidScoutAttribute() {
  var additionalAttribute = {};
  return additionalAttribute;
};
var getObject = exports.getObject = function getObject(p, o) {
  return p.reduce(function (xs, x) {
    return xs && xs[x] ? xs[x] : null;
  }, o);
};
var getPolicyEligibility = exports.getPolicyEligibility = function getPolicyEligibility(policyResponse, policyType) {
  var checkType = getObject(['policyEligible'], policyResponse) && policyResponse.policyEligible.find(function (x) {
    return x.option.toLowerCase() === policyType.toLowerCase();
  }); // check for case
  return checkType ? checkType.isEligible : false;
};
var getPcEligibility = exports.getPcEligibility = function getPcEligibility(policyResponse) {
  var checkType = getObject(['policyEligible'], policyResponse) && policyResponse.policyEligible.find(function (x) {
    return x.isEligible === true;
  });
  return checkType && checkType.option;
};
var setAdditionalAttribute = exports.setAdditionalAttribute = function setAdditionalAttribute(registrationData) {
  var additionalAttribute = {};
  if (registrationData) {
    additionalAttribute.buName = registrationData.buName ? registrationData.buName : '';
    additionalAttribute.businessUnitCode = registrationData.businessUnitCode ? registrationData.businessUnitCode : '';
    additionalAttribute.registrationSource = registrationData.userDetails && registrationData.userDetails.registration_source ? registrationData.userDetails.registration_source : null;
    if (registrationData.policyCountryCode) {
      additionalAttribute.policyCountryCode = registrationData.policyCountryCode ? registrationData.policyCountryCode : '';
    } else if (registrationData.policyType) {
      additionalAttribute.policyCountryCode = registrationData.policyType ? registrationData.policyType : '';
    } else if (registrationData.business && registrationData.business.address) {
      additionalAttribute.policyCountryCode = registrationData.business.address[0].countryCode ? registrationData.business.address[0].countryCode : '';
    }
    if (registrationData.policies && registrationData.policies[0]) {
      additionalAttribute.policyNumber = "".concat(registrationData.policies[0].number, "-").concat(registrationData.policies[0].formCode);
    } else {
      additionalAttribute.policyNumber = registrationData.policyNumber ? registrationData.policyNumber : '';
    }
    additionalAttribute.zipCode = registrationData.zipCode ? registrationData.zipCode : '';
    if (registrationData.singleAccount === true) {
      additionalAttribute.singleAccount = 'singleaccount';
    } else if (registrationData.singleAccount === false) {
      additionalAttribute.singleAccount = 'multipleaccount';
    } else {
      additionalAttribute.singleAccount = '';
    }
    additionalAttribute.insuranceType = registrationData.insuranceType ? registrationData.insuranceType : '';
    if (registrationData.userDetails) {
      additionalAttribute.userId = registrationData.userDetails ? registrationData.userDetails.userId : '';
    } else {
      additionalAttribute.userId = registrationData.userId ? registrationData.userId : '';
    }
    if (registrationData.inviteUserDetails) {
      additionalAttribute.businessRole = registrationData.inviteUserDetails.businessRoleName ? registrationData.inviteUserDetails.businessRoleName : registrationData.inviteUserDetails.business.businessRole;
    } else if (registrationData.business) {
      additionalAttribute.businessRole = registrationData.business.businessRole ? registrationData.business.businessRole : '';
    } else if (registrationData.businessRoleName) {
      additionalAttribute.businessRole = registrationData.businessRoleName;
    } else {
      additionalAttribute.businessRole = registrationData.businessRole ? registrationData.businessRole : '';
    }
    additionalAttribute.sai = registrationData.sai ? registrationData.sai : '';
    if (registrationData.tokenSource === 'SEAMLESSBISELECTINVITE') {
      additionalAttribute.emailId = getObject(['individual', 'inviteEmail'], registrationData);
      additionalAttribute.tokenSource = 'SEAMLESSBISELECTINVITE', additionalAttribute.sai = getObject(['accountNumber'], registrationData);
      additionalAttribute.access = getObject(['accessLevel'], registrationData);
      additionalAttribute.inviteeEmail = getObject(['userProfile', 'email'], registrationData);
    }
  }
  return additionalAttribute;
};
var setAdditionalPCAttribute = exports.setAdditionalPCAttribute = function setAdditionalPCAttribute(registrationData, userId) {
  var additionalAttribute = {};
  if (registrationData) {
    additionalAttribute.buName = registrationData.businessUnitName ? registrationData.businessUnitName : '';
    additionalAttribute.businessUnitCode = registrationData.businessUnitCode ? registrationData.businessUnitCode : '';
    additionalAttribute.userId = userId || '';
    additionalAttribute.sai = registrationData.sai ? registrationData.sai : '';
    additionalAttribute.registrationSource = registrationData.registrationSource ? registrationData.registrationSource : null;
  }
  return additionalAttribute;
};
var GetAdditionalAttributes = exports.GetAdditionalAttributes = function GetAdditionalAttributes(userId) {
  var additionalAttributes = {};
  var store = require('store/store');
  var registrationReducerData = store["default"].getState().registrationDataReducer;
  var registrationData = registrationReducerData && Object.keys(registrationReducerData).length > 0 ? registrationReducerData : null;
  var seamlessReducerData = store["default"].getState().inviteReducer;
  var seamlessData = seamlessReducerData && Object.keys(seamlessReducerData).length > 0 ? seamlessReducerData : null;
  var bondfidata = store["default"].getState().primaryCredentialsReducer;
  var bondfiattr = bondfidata && bondfidata.pcResponse && bondfidata.pcResponse.eligiblePolicyFound && bondfidata.pcResponse.primaryContactFound && bondfidata.additionalAttribute && Object.keys(bondfidata.additionalAttribute).length > 0 ? bondfidata.additionalAttribute : null;
  if (seamlessData !== null) {
    additionalAttributes = seamlessData.tokenSource === "SEAMLESSBIDSCOUTINVITE" ? setAdditionalBidScoutAttribute() : setAdditionalAttribute(seamlessData);
  } else if (bondfiattr !== null) {
    additionalAttributes = setAdditionalPCAttribute(bondfiattr, userId);
  } else if (registrationData !== null) {
    additionalAttributes = setAdditionalAttribute(registrationData);
  }
  return additionalAttributes;
};
var getParameterByName = exports.getParameterByName = function getParameterByName(name, search) {
  var match = RegExp("[?&]".concat(name, "=([^&]*)")).exec(search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};
var getSplitedNumber = exports.getSplitedNumber = function getSplitedNumber(strVal) {
  var tempArr = strVal.split('');
  return Array.isArray(tempArr) ? tempArr.join(' ') : null;
};