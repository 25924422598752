"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = configureStore;
var _redux = require("redux");
var _reduxImmutableStateInvariant = _interopRequireDefault(require("redux-immutable-state-invariant"));
var _reducers = _interopRequireDefault(require("../reducers"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function configureStore(initialState) {
  return (0, _redux.createStore)(_reducers["default"], initialState, (0, _redux.applyMiddleware)((0, _reduxImmutableStateInvariant["default"])()));
}