"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SET_USER_DETAILS = exports.SET_USER_CREDENTIALS = exports.SET_PRIMARY_CONTACT_DETAILS = exports.SET_POLICY_ELIGIBILITY = exports.SET_OTHER_REG_TYPE = exports.SET_MODAL_LINK = exports.SET_INVITE_USERDETAILS = exports.SET_INVITE_DETAILS = exports.SET_ERROR_MESSAGES = exports.SET_ELIGIBLE_APPS = exports.SET_ALERT_VISIBILITY = exports.RESET_STATE = void 0;
var SET_POLICY_ELIGIBILITY = exports.SET_POLICY_ELIGIBILITY = 'SET_POLICY_ELIGIBILITY';
var SET_USER_DETAILS = exports.SET_USER_DETAILS = 'SET_USER_DETAILS';
var SET_ERROR_MESSAGES = exports.SET_ERROR_MESSAGES = 'SET_ERROR_MESSAGES';
var SET_MODAL_LINK = exports.SET_MODAL_LINK = 'SET_MODAL_LINK';
var RESET_STATE = exports.RESET_STATE = 'RESET_STATE';
var SET_OTHER_REG_TYPE = exports.SET_OTHER_REG_TYPE = 'SET_OTHER_REG_TYPE';
var SET_ALERT_VISIBILITY = exports.SET_ALERT_VISIBILITY = 'SET_ALERT_VISIBILITY';
var SET_INVITE_DETAILS = exports.SET_INVITE_DETAILS = 'SET_INVITE_DETAILS';
var SET_INVITE_USERDETAILS = exports.SET_INVITE_USERDETAILS = 'SET_INVITE_USERDETAILS';
var SET_PRIMARY_CONTACT_DETAILS = exports.SET_PRIMARY_CONTACT_DETAILS = 'SET_PRIMARY_CONTACT_DETAILS';
var SET_ELIGIBLE_APPS = exports.SET_ELIGIBLE_APPS = 'SET_ELIGIBLE_APPS';
var SET_USER_CREDENTIALS = exports.SET_USER_CREDENTIALS = 'SET_USER_CREDENTIALS';